/** ****************************************************************************
 *  Blake's Portfolio (Frontend)
 *  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 *  
 *  v0.1
 *  by Blake (organizedSlop)
 * 
 * 
 *  TODO: xxxxx
 * 
 *  ****************************************************************************
 */


import React, { useEffect, useState } from "react";

import axios from "axios";

import "./App.css";

import Feed   from "./components/Feed.js";
import Footer from "./components/Footer.js";
import Header from "./components/Header.js";
import Page   from "./components/Page.js";



/** ============================================================================
 */
export default function App() {

    const apiRoot = /* process.env.NODE_ENV == "development" ? "" : */ process.env.REACT_APP_API_ROOT;
    const development = process.env.NODE_ENV == "development";
    
    const postsPerPage = 25;

    const [page, setPage] = useState(1);
    const [feedType, setFeedType] = useState("list");
    const [posts, setPosts] = useState([]);
   
    let content = "";

    // TODO: Handle pagination
    useEffect(() => {

        if (development) { 
            console.log(`**************************************************************************** \
                         DEVELOPMENT                                                                  \
                         - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  \
                         API root: ${apiRoot}                                                         \
                         **************************************************************************** \
                         `);
        }

        let url = `${apiRoot}/wp-json/wp/v2`;
        
        switch (window.location.pathname) {
            case "/art":
                url = url + "/posts/?_embed&per_page=25&category_slug=art";
                setPage("art");
                break;

            case "/me":
                url = url + "/pages/?slug=me";
                setPage("me");
                break;

            case "/portals":
                url = url + "/pages/?slug=portals"
                setPage("portals");
                break;

            case "/projects":
                url = url + "/posts/?_embed&per_page=25&category_slug=projects";
                setPage("projects");
                break;
            
            default:
                url = url + "/posts/?_embed&per_page=25";
                setPage("home");
                break;
        }

        /** - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
         *  Get the global styles info from the WordPress API
         */ 
        axios.get(`${apiRoot}/wp-json/wp/v2/global-styles/styles`)
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error.message);
            });


        axios.get(url)
            // Handles success
            .then((response) => {
                setPosts(response.data);
                // response.data.forEach((post) => {
                //     axios.get(`${apiRoot}/wp-json/vip-block-data-api/v1/posts/${post.id}/blocks`)
                //         .then((response) => {
                //             console.log("Response:", response.data.blocks);     
                //         })
                //         .catch((error) => {
                //             // console.log(error);
                //         })
                //     });
            })
            .catch((error) => {
                // Handles failure
                console.log(error);
            })
            .then(() => {
                // Always executed
            });            
    }, []);

    switch (page) {
        
        // TODO: Add handling for the diff existing pages
        //
        //
        // case "art":
        //     content = <>
        //         <div>
        //             <a>Portals</a>
        //         </div>
        //         <Page content={ posts } />
        //     </>;
        //     break;

        case "me":
            content = <Page content={ posts } />;
            break;

        default:
            content = <Feed posts={ posts } type={ feedType } apiRoot={ apiRoot } />;
            break;
    }


    return (
        <div className="App">
            <link rel="stylesheet" href={ `${apiRoot}/wp-content/themes/twentytwentyfour/style.css` } />
            <div className="content-outer-box" style={{ overflowX: "clip" }}>
                <Header feedType={ feedType }
                        gridViewButtonOnClick={() => { setFeedType("grid") }} 
                        listViewButtonOnClick={() => { setFeedType("list") }}  />
                    <div className="content-inner-box" style={{ paddingTop: "4rem" }}>
                        { content }
                    </div>
                <Footer />
            </div>
        </div>
    );
}

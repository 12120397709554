import React, { useState, ReactNode } from "react";


export default function Swipeable(props:SwipeableProps) {

    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);

    const [touchStartX, setTouchStartX] = useState(0)
    const [touchEndX, setTouchEndX] = useState(0)

    const [touchStartY, setTouchStartY] = useState(0)
    const [touchEndY, setTouchEndY] = useState(0)

    const minSwipeDistance = 120


    function onTouchStart(event:any) {
        // Reset offsets
        setOffsetX(0);
        setOffsetY(0);

        // Set start and end coordinates if touch event
        if (event.targetTouches != undefined) {
            if (event.targetTouches.length > 0) {
                setTouchStartX(event.targetTouches[0].clientX)
                setTouchEndX(event.targetTouches[0].clientX)
                setTouchStartY(event.targetTouches[0].clientY)
                setTouchEndY(event.targetTouches[0].clientY)
            }

        // Set start and end coordinates if mouse event
        } else if (event.buttons) {
            setTouchStartX(event.clientX)
            setTouchEndX(event.clientX);
            setTouchStartY(event.clientY)
            setTouchEndY(event.clientY);
        }
    }

    function onTouchMove(event:any) {
        if (event.targetTouches != undefined) {
            if (event.targetTouches.length > 0) {
                setTouchEndX(event.targetTouches[0].clientX)
                setTouchEndY(event.targetTouches[0].clientY)
            }
        } else if (event.buttons) {
            setTouchEndX(event.clientX)
            setTouchEndY(event.clientY)

            let newOffsetX = 0;
            if (Math.abs(touchEndX - touchStartX) >= 10) {
                setOffsetX(touchEndX - touchStartX);
                console.log("offset:", touchEndX-touchStartX, touchStartX, touchEndX)
            }
        }
    }

    function onTouchEnd() {
        if (touchStartX && touchEndX) swipeHorizontal()
        if (touchStartY && touchEndY) swipeVertical()

        setOffsetX(0);
        setOffsetY(0);
    }

    function swipeHorizontal() {

        const xDistance = touchStartX - touchEndX
        const yDistance = touchStartY - touchEndY
        if (Math.abs(yDistance) >= Math.abs(xDistance)) {
            return;
        }

        const isLeftSwipe = xDistance > minSwipeDistance
        const isRightSwipe = xDistance < -minSwipeDistance

        if (isLeftSwipe && props.onSwipeLeft) {
            console.log("Swipe left");
            props.onSwipeLeft();
        }

        if (isRightSwipe && props.onSwipeRight) {
            console.log("Swipe right");
            props.onSwipeRight();
        }
    }

    function swipeVertical() {

        const xDistance = touchStartX - touchEndX
        const yDistance = touchStartY - touchEndY
        if (Math.abs(xDistance) >= Math.abs(yDistance)) {
            return;
        }

        const isUpSwipe = yDistance > minSwipeDistance
        const isDownSipe = yDistance < -minSwipeDistance

        if (isDownSipe && props.onSwipeDown) {
            props.onSwipeDown();
        }

        if (isUpSwipe && props.onSwipeUp) {
            props.onSwipeUp();
        }
    }

    return (
        <div onMouseDown={onTouchStart}
        onMouseMove={onTouchMove}
        onMouseUp={onTouchEnd}
        onMouseLeave={(event) => { if (event.buttons) { onTouchEnd(); }}}
        style={{ transform: `translate(${offsetX}px, ${offsetY}px)`, width: `${props.width}` }}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd} >
            {props.children}
        </div>
    )
}

export interface SwipeableProps {
    children:ReactNode,
    width?: "",
    onSwipeLeft?: () => void,
    onSwipeRight?: () => void,
    onSwipeUp?: () => void,
    onSwipeDown?: () => void
}
import { useEffect, 
         useCallback, 
         useState, 
         useRef } from "react";

import ArrowToggle         from "./ArrowToggle.js";
import Color               from "./Color.js";                                                                                                                                                                                                                                                        
import ColorMarker         from "./ColorMarker.js";
import ColorPalette        from "./ColorPalette.js";
import ColorPicker         from "./ColorPicker.js";
import ColorPreview        from "./ColorPreview.js";
import ColorPropertySlider from "./ColorPropertySlider.js";
import ColorSwatch         from "./ColorSwatch.js";
import Group               from "../Group.js"
import ImagesMenu          from "./ImagesMenu";
import TextButton          from "./TextButton";
import TextToggle          from "./TextToggle.js";

import "./ColorOrganizerStyle.css";


/** ****************************************************************************
 *  Color Organizer
 *  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 *  v0.1 [2024-05-02 000000]
 *  Blake (organizedSlop)
 *   
 * 
 *  Possible way to improve performance: call each getMostSimilarColor as async, returning the
 *  default color space in the meantime, then splicing in the result of each func call to its
 *  index
 * 
 *  TODO: Add "real" color preview when image is selected
 *  TODO: Add some kind of caching for calculations to speed things up
 *  TODO: Add some sort of accuracy/quality slider for performance
 *  TODO: Don't render gradient color space when image is selected
 *  TODO: Add way to deselect all images
 *  TODO: Add proper asynchronous handling for calculating color spaces
 *  TODO: Clicking color on palette needs to move marker to the correct place
 *  TODO: Add handling marker placement for changing viewport size
 *  TODO: Add img info to UI for debugging -- unique colors, size, format
 *  TODO: Break out color palette controls into component -- like image menu controls
 *  TODO: Swap order of palette and color controls
 *  TODO: Make dynamic the order of the main app elements: images, picker, palette, color
 *  TODO: Change pickerOnDrag to be called on mouse move by a parent of the picker so dragging out of the picker will still assign the closest color
 * 
 *  FIX: Dragging/clicking cursor in picker when an image is selected
 *  FIX: Change so selected color is derived from pickerCanvas if image is selected
 * 
 *  TEST: Error handling for invalid uploads
 *  TEST: Performance using large images
 *  TEST: Accuracy for images with few colors
 * 
 *  IDEA: Add default images
 * 
 *  ****************************************************************************
 */
function ColorOrganizer() {

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Environment variables

    const isDevelopment = process.env.NODE_ENV == "development";
    const apiRoot = isDevelopment ? "" : process.env.REACT_APP_API_ROOT;


    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Defaults / constants

    const defaultColor       = new Color(null, null, null, null, null, null, "000000", 0);
    const defaultPaletteSize = 22;
    const pickerCanvasSize   = 480; // px


    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // UI states

    const [isShowingMenu,          setIsShowingMenu]          = useState(false);
    const [isShowingAlphaSlider,   setIsShowingAlphaSlider]   = useState(true);
    const [isShowingHSLSliders,    setIsShowingHSLSliders]    = useState(true);
    const [isShowingRGBSliders,    setIsShowingRGBSliders]    = useState(true);

    const selectorRefs = useRef();
    const canvasRefs   = useRef();

    const [hueSelectorCanvasComponent, setHueSelectorCanvasComponent] = useState(<></>);
    const [pickerCanvasComponent,      setPickerCanvasComponent]      = useState(<></>);
    const [imageCanvasComponent,       setImageCanvasComponent]       = useState(<></>);


    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Color palette states
    
    const [colorPalette,       setColorPalette]       = useState(Array(defaultPaletteSize).fill(defaultColor));
    const [currentColor,       setCurrentColor]       = useState(new Color());
    const [markerCoordinates,  setMarkerCoordinates]  = useState({ x: 0, y: 0 });
    const [selectedIndex,      setSelectedIndex]      = useState(0);

    const [currentHue, setCurrentHue] = useState(0);
    
    
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Image states

    const [images, setImages] = useState([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const imageNumberLimit = 10;

    
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Mouse event handling

    const [isMouseDown, setIsMouseDown] = useState(false);

    const handleMouseDown = useCallback((e) => {
        const isMouseDown = true;
        setIsMouseDown(isMouseDown);
    }, []);

    const handleMouseUp = useCallback((e) => {
        const isMouseDown = false;
        setIsMouseDown(isMouseDown);
    }, []);

    const handleMouseMove = useCallback((e) => {
    }, []);

    useEffect(() => {
        window.addEventListener("mousedown", handleMouseDown);
        window.addEventListener("mouseup", handleMouseUp);
        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousedown", handleMouseDown);
            window.removeEventListener("mouseup", handleMouseUp);
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, [handleMouseDown, handleMouseUp, handleMouseMove]);




    /** ========================================================================
     *  Functions
     *  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
     */
    function hueSelectorOnClick(event) {
        const hue = getHueAtCoordinate(event.clientX, event.clientY);
        setCurrentHue(hue);
        console.log("Mouse x:", event.clientX, "Mouse y:", event.clientY, "Hue:", hue);
        const color = new Color(hue, currentColor.saturation, currentColor.brightness);
        setCurrentColor(color); 
    }

    function hueSelectorOnDrag(event) {
        if ((event.movementX != 0 || event.movementY != 0) && isMouseDown) {
            if (selectorRefs.current !== undefined) {
                const hueSelectorBounds = selectorRefs.current.hueSelector.getBoundingClientRect();
                const inBounds = isMouseInBounds(event.clientX, event.clientY, hueSelectorBounds);
                
                if (inBounds.x) {

                    let hue = getHueAtCoordinate(event.clientX, event.clientY);

                    if (!inBounds.y) {
                        if (event.clientY > hueSelectorBounds.bottom) {
                            hue = 0;
                        } else {
                            hue = 360;
                        }
                    }

                    const color = new Color(hue, currentColor.saturation, currentColor.brightness);
                    setCurrentColor(color);
                }
            }
        }
    }

    function pickerOnClick(event) {
        const color = getColorAtCoordinate(event.clientX, event.clientY);
        setCurrentColor(color);
        const mouseCoordinates = getAdjustedMouseCoordinates(event.clientX, event.clientY);
        setMarkerCoordinates(mouseCoordinates);
    }

    function pickerOnDrag(event) {
        if ((event.movementX != 0 || event.movementY != 0) && isMouseDown) {
            if (selectorRefs.current !== undefined) {
                let newMarkerCoordinates = getAdjustedMouseCoordinates(event.clientX, event.clientY);
                const pickerBounds = selectorRefs.current.picker.getBoundingClientRect()
                const inBounds = isMouseInBounds(event.clientX, event.clientY, pickerBounds);

                if (inBounds.x || inBounds.y) {

                    if (!inBounds.x) {
                        newMarkerCoordinates.x = markerCoordinates.x;
                    }
                    if (!inBounds.y) {
                        newMarkerCoordinates.y = markerCoordinates.y;
                    }

                    const color = getColorAtCoordinate(event.clientX, event.clientY);
                    setCurrentColor(color);
                    setMarkerCoordinates(newMarkerCoordinates);
                }
            }
        }
    }




    function getAdjustedMouseCoordinates(x, y) {
        let mouseX = x;
        let mouseY = y;
        
        let adjustedMouseX = mouseX; 
        let adjustedMouseY = mouseY + window.scrollY;

        return { x: adjustedMouseX, 
                 y: adjustedMouseY };
    }


    /**
     *  Expects bounds to conform to DOMRect type
     */
    function isMouseInBounds(x, y, bounds) {
        
        let inBoundsHorizontal = x >= bounds.left &&
                                 x <= bounds.right;

        let inBoundsVertical = y >= bounds.top &&
                               y <= bounds.bottom;

        console.log(`Mouse at (${x}, ${y}) ${inBoundsHorizontal && inBoundsVertical ? "IS" : "is NOT"} within the bounds [(${bounds.left}, ${bounds.top}), (${bounds.right}, ${bounds.top}), (${bounds.right}, ${bounds.bottom}), (${bounds.left}, ${bounds.bottom})]`);
        
        return { x: inBoundsHorizontal, 
                 y: inBoundsVertical };
    }


    /**
     *  Get hue at Coordinate
     */
    function getHueAtCoordinate(x, y) {
        // Refs are always undefined for the first rendering
        if (selectorRefs.current !== undefined) {

            const hueSelectorBounds = selectorRefs.current.hueSelector.getBoundingClientRect();
            console.log(`Hue selector bounds: ${JSON.stringify(hueSelectorBounds)}`)

            const isInBounds = isMouseInBounds(x, y, hueSelectorBounds);

            if (true) { //isInBounds.x && isInBounds.y) {
                const hue = 360 * (1 - ((y - hueSelectorBounds.top) / hueSelectorBounds.height));
                console.log(`Hue: ${hue}`);
                return hue;

            } else {
                return currentColor.hue;
            }

        } else {
            return defaultColor.hue;
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Get Color at Coordinate

    function getColorAtCoordinate(x, y) {  

        // Refs are always undefined for the first rendering
        if (selectorRefs.current !== undefined) {

            const pickerBounds = selectorRefs.current.picker.getBoundingClientRect();
            const isInBounds = isMouseInBounds(x, y, pickerBounds);
            const hue = currentColor.hue;
            const alpha = currentColor.alpha;

            let saturation = currentColor.saturation;
            let brightness = currentColor.brightness;

            if (isInBounds.x && isInBounds.y) {
                // Update saturation and brightness values
                saturation = (x - pickerBounds.left) / pickerBounds.width;
                brightness = 1 - ((y - pickerBounds.top) / pickerBounds.height);
            }

            return new Color(hue, saturation, brightness, null, null, null, null, alpha);

        } else {
            return defaultColor;
        }
    }



    function getCoordinatesForColor() {
        // Refs are always undefined for the first rendering
        if (selectorRefs.current !== undefined) {
            const pickerBounds = selectorRefs.current.picker.getBoundingClientRect();

            let adjustedX =   currentColor.saturation  * pickerBounds.width  + pickerBounds.x;
            let adjustedY = -(currentColor.brightness) * pickerBounds.height + pickerBounds.height + pickerBounds.y;
            
            return { x: adjustedX, y: adjustedY };

        } else {
            return { x: 0, y: 0 };
        }
    }



    // Called when swatch is clicked
    // Sets the selected swatch index and sets the current color to the swatch's color
    function selectSwatch(event, index) {
        event.preventDefault();
        setSelectedIndex(index);
        let newColor = Object.assign(new Color(), colorPalette[index]);
        setCurrentColor(newColor);
    }

    // Called when "replace" button is clicked
    // Sets the selected swatch to the current color
    function replaceSwatch() {
        console.log(`Set swatch ${selectedIndex} to #${currentColor.hex}${Math.round(currentColor.alpha * 255).toString(16).padStart(2, "0")}.`);
        let currentColorPalette = Object.assign({}, colorPalette);
        currentColorPalette[selectedIndex] = currentColor;
        setColorPalette(currentColorPalette);
    }


    // Add an new swatch to the palette
    function addSwatch() { }

    function deleteSwatch() { }

    function clearPalette() { }

    function selectColor(event) {
        event.preventDefault();
        let newColor = Object.assign(new Color(), currentColor);

        switch (event.target.name) {
            case "hue":
                newColor.hue = Number(event.target.value);
                newColor.setRGB();
                newColor.setHex();
                break;

            case "saturation":
                newColor.saturation = Number(event.target.value);
                newColor.setRGB();
                newColor.setHex();
                break;

            case "brightness":
                newColor.brightness = Number(event.target.value);
                newColor.setRGB();
                newColor.setHex();
                break;

            case "red":
                newColor.red = Number(event.target.value);
                newColor.setHSV();
                newColor.setHex();
                break;

            case "green":
                newColor.green = Number(event.target.value);
                newColor.setHSV();
                newColor.setHex();
                break;

            case "blue":
                newColor.blue = Number(event.target.value);
                newColor.setHSV();
                newColor.setHex();
                break;

            case "alpha":
                newColor.alpha = Number(event.target.value);
                break;

            default:
                console.log("Error: ", event.target.name); 
                break;
        }

        setCurrentColor(newColor);
    }

// -----------------------------------------------------
// image functions

function onChangeImages(images, addUpdateIndex) {
        setImages(images);
    }   


















    /** ========================================================================
     *  Return
     *  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
     */
    return (
        <>
            <div className="color-organizer color-organizer-outer-box">
                <div className="color-organizer color-organizer-inner-box"> 
                    { /** - - - - - - - - - - - - - - - - - - - - - - - - - - -
                       *  Images Menu
                       */ }
                    <ImagesMenu images             = { images }
                                imageNumberLimit   = { imageNumberLimit }
                                selectedHue        = { currentHue }
                                selectedImageIndex = { selectedImageIndex } 
                                onChangeImages     = { onChangeImages }
                                onChangeImageCanvasComponent  = { (component) => { console.log("Setting imageCanvasComponent"); setImageCanvasComponent(component); } }
                                onChangePickerCanvasComponent = { (component) => setPickerCanvasComponent(component) }
                                onChangeSelectedImageIndex    = { (index) => { console.log(`Setting selected image to ${index}`); setSelectedImageIndex(index); } } 
                                pickerCanvasSize   = { pickerCanvasSize }

                                canvasRefs = { canvasRefs } />                   
                    
                    <div className="color-organizer-top-menu-box">
                        {/* <TextButton label="Open" />
                        <TextButton label="Save" />
                        <TextButton label="Share" />
                        <TextButton label="Palettes" />
                        <TextButton label="Images" />
                        <div style={ { width: "100%" } }></div>
                        <TextButton label="Round Palette" /> */}
                    </div>
                    { imageCanvasComponent }
                    <ColorPicker currentColor          = { currentColor } 
                                 markerCoordinates     = { markerCoordinates } 
                                 hueSelectorCanvasComponent = { hueSelectorCanvasComponent }
                                 hueSelectorOnClick    = { (event) => { hueSelectorOnClick(event) } }
                                 hueSelectorOnDrag     = { (event) => { hueSelectorOnDrag(event) } }
                                 pickerCanvasComponent = { pickerCanvasComponent }
                                 pickerOnClick         = { (event) => { pickerOnClick(event) } } 
                                 pickerOnDrag          = { (event) => { pickerOnDrag(event) } } 
                                 
                                 ref = { selectorRefs } />
                    
                    <ColorPalette currentColor  = { currentColor } 
                                  colors        = { colorPalette } 
                                  selectedIndex = { selectedIndex } 
                                  selectSwatch  = { selectSwatch } 
                                  replaceSwatch = { replaceSwatch } 
                                  deleteSwatch  = { deleteSwatch } 
                                  addSwatch     = { addSwatch } 
                                  clearPalette  = { clearPalette } />
                    
                    { /** - - - - - - - - - - - - - - - - - - - - - - - - - - -
                      *   Color palette Menu
                      */ }
                    <div className="color-organizer-menu-box group-box vertical">
                        {/* Menu bar */}
                        <div className="color-organizer-menu-bar">
                            <div className="color-organizer-menu-bar-leading-box">
                                <div className="color-organizer-menu-bar-leading">
                                    <TextButton label="Assign"  onClick={ replaceSwatch } />
                                    <TextButton label="Add"     onClick={ addSwatch }     />
                                    <TextButton label="Delete"  onClick={ deleteSwatch }  />
                                </div>
                            </div>

                            <div className="color-organizer-menu-bar-trailing-box">
                                <div className="color-organizer-menu-bar-trailing">
                                    <TextToggle label="HSL" />
                                    <TextToggle label="RGB" />
                                    <TextToggle label="Hex" />
                                    <TextToggle label="Alpha" />
                                    {/* <div style={{width: "100%"}}></div> */}
                                    <ArrowToggle onChange={ (toggleState) => { setIsShowingMenu(toggleState) } } />
                                </div>
                            </div>
                        </div>

                        <div className="color-organizer-sliders-box">
                            <ColorPropertySlider label="Hue"         name="hue"        value={ currentColor.hue }        min={ 0 } max={ 360 } step={ 0.01 } onChange={ (event) => selectColor(event) } isHidden={ !(isShowingMenu && isShowingHSLSliders) } />
                            <ColorPropertySlider label="Saturation"  name="saturation" value={ currentColor.saturation } min={ 0 } max={ 1 }   step={ 0.01 } onChange={ (event) => selectColor(event) } isHidden={ !(isShowingMenu && isShowingHSLSliders) } />
                            <ColorPropertySlider label="Brightness"  name="brightness" value={ currentColor.brightness } min={ 0 } max={ 1 }   step={ 0.01 } onChange={ (event) => selectColor(event) } isHidden={ !(isShowingMenu && isShowingHSLSliders) } />
                            <ColorPropertySlider label="Red"         name="red"        value={ currentColor.red }        min={ 0 } max={ 255 } step={ 0.01 } onChange={ (event) => selectColor(event) } isHidden={ !(isShowingMenu && isShowingRGBSliders) } />
                            <ColorPropertySlider label="Green"       name="green"      value={ currentColor.green }      min={ 0 } max={ 255 } step={ 0.01 } onChange={ (event) => selectColor(event) } isHidden={ !(isShowingMenu && isShowingRGBSliders) } />
                            <ColorPropertySlider label="Blue"        name="blue"       value={ currentColor.blue }       min={ 0 } max={ 255 } step={ 0.01 } onChange={ (event) => selectColor(event) } isHidden={ !(isShowingMenu && isShowingRGBSliders) } />
                            <ColorPropertySlider label="Alpha"       name="alpha"      value={ currentColor.alpha }      min={ 0 } max={ 1 }   step={ 0.01 } onChange={ (event) => selectColor(event) } isHidden={ !(isShowingMenu && isShowingAlphaSlider)} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ColorOrganizer;

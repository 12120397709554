import React from "react";

import Post from "./Post";


export default function Page({content}) {

    //
    const postComponents = content.map((post, index) => {
        return (
            <Post post={post} key={index} type="page" />
        )
    });
    return (
        <div className="group-box vertical no-wrap h-align-center">
            {postComponents}
        </div>
    )
}
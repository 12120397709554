import { useRef, 
         useImperativeHandle } from "react";


export default function HueMarker({ hue, onClick }) {

    return (

        /** Subtract half the diameter of the marker 
         *  when setting it's location
         */
        <div style={ { display:        "flex", 
                       flexWrap:       "nowrap", 
                       justifyContent: "flex-end", 
                       alignItems:     "center",
                       position:       "relative",
                       top:            `${ 30 - (parseFloat(hue)/360 * 30) - 0.05 }rem` } }>

            <svg viewBox="0 0 100 100"
                 style={ { //position: "relative",
                        width: "0.667rem",
                        height: "0.667rem",
                        position: "absolute"
                        //float: "right",
                        //top: `${28 - (parseFloat(hue)/360 * 28) - 1 }rem` 
                        } }>
                <polygon style={ { fill: "white",
                                   stroke: "white",
                                   strokeWidth: "0.667rem",
                                   position: "absolute", 
                                   filter: "drop-shadow(-0.333rem 0 0.5rem #515352)" } } 
                         points="100,100 100,0 33,50"/>
            </svg>
            <div style={{ width: "100%", height: "0.1rem", backgroundColor: "#ffffffaa" }}></div>
        </div>
    );
    
};

        /*
        <div className="color-organizer-hue-marker" 
             id="color-organizer-hue-marker" 
             style={ { 
                left: "0.8rem",
                width: "0",
                height: "0",
                borderTop: "1rem solid transparent",
                borderBottom: "1rem solid transparent",
                borderRight: "1rem solid white",
                position: "relative",
                bottom: hue,
                top: `${28 - (parseFloat(hue)/360 * 28) - 1 }rem` } }
        ></div>
        */ 
import React from "react";

import "./TextButton.css";


export default function TextButton({ onClick = () => { }, 
                                     label = "", 
                                     backgroundColor = "lightgray" }) {

    return (
        <button className="text-button" 
                onClick={ onClick }
                style={ { backgroundColor: backgroundColor } }>
            
            { label }
        
        </button>
    );
}
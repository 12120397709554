import { forwardRef,
         useEffect } from "react";




const Canvas = forwardRef(function({ imageData       = null,
                                     pixelData       = null,
                                     canvasWidth     = null,
                                     canvasHeight    = null,
                                     isLoaded        = () => {},
                                     additionalStyle = {} }, 
                                     
                                     ref) {
    
    /**
     *  Create the style object to pass to the canvas element
     */
    const defaultStyle = { 
        border: "1px solid orange",
        imageRendering: "-moz-crisp-edges",
        imageRendering: "-webkit-crisp-edges",
        imageRendering: "pixelated",
        imageRendering: "crisp-edges"
    };
    let style = Object.assign({}, defaultStyle, additionalStyle);




    useEffect(() => {
        if (ref !== undefined) {
            if (imageData) {
                const image = new Image();

                image.onload = () => {
                    const canvas = ref.current;
                    canvas.width  = canvasWidth;
                    canvas.height = canvasHeight;
                    
                    const context = canvas.getContext("2d");
                    context.imageSmoothingEnabled       = false;
                    context.webkitImageSmoothingEnabled = false;
                    context.mozImageSmoothingEnabled    = false;
                    context.msImageSmoothingEnabled     = false;
                    context.oImageSmoothingEnabled      = false;

                    context.clearRect(0, 0, canvas.width, canvas.height);
                    context.drawImage(image, 0, 0, canvas.width, canvas.height);  

                    isLoaded(true);
                };
                image.src = imageData;
                
            } else if (pixelData) {
                console.log("Has pixel data");
                const canvas = ref.current;
                canvas.width  = canvasWidth
                canvas.height = canvasHeight

                const context = canvas.getContext("2d");
                context.imageSmoothingEnabled       = false;
                context.webkitImageSmoothingEnabled = false;
                context.mozImageSmoothingEnabled    = false;
                context.msImageSmoothingEnabled     = false;
                context.oImageSmoothingEnabled      = false;
                
                const image = context.createImageData(canvas.width, canvas.height);
                console.log("Create image data");
                image.data.set(pixelData);
                
                context.clearRect(0, 0, canvas.width, canvas.height);
                console.log("putImageData");
                context.putImageData(image, 0, 0);
            }
        } else {
            const canvas = ref.current;
            canvas.width  = canvasWidth;
            canvas.height = canvasHeight;

            const context = canvas.getContext("2d");

            context.fillStyle = "magenta";
            context.fillRect(0, 0, canvas.width, canvas.height);
        }
    }, [imageData, pixelData]);



    /** ========================================================================
     *  Return
     *  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
     */
    return (
        <canvas ref={ ref }
                width={ canvasWidth }
                height={ canvasHeight }
                style={ style }></canvas>
    );
});

export default Canvas;

import React, { useState } from "react";


export default function TextToggle({ 
    toggled  = true, 
    label    = "",
    onChange = { } }) {

    const [toggleState, setToggleState] = useState(toggled);

    return (
        <>
            <style>{`
                .text-toggle-label {
                    position: relative;
                    display: inline-block;
                    height: fit-content;
                    display: flex;
                    align-items: flex-start;
                    margin: 0.125rem;
                }

                .text-toggle-input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }

                .text-toggle-slider {
                    font-size: 0.6rem;
                    display: inline-block;
                    line-height: 0.6rem;
                    font-family: sans-serif;
                    text-transform: uppercase;
                    cursor: pointer;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                    color: black;
                    background-color: lightgray;
                    padding: 0.2rem 0.5rem 0.1rem 0.5rem;
                    border-radius: 999rem;
                }

                input:checked + .text-toggle-slider {
                    color: white;
                    background-color: black;

            `}</style>
            <label className="text-toggle-label">
                <input className="text-toggle-input" 
                       type="checkbox" 
                       checked={ toggleState } 
                       onChange={ () => {
                            setToggleState(!toggleState); 
                            onChange(toggleState); } } />
                <span className="text-toggle-slider">{ label }</span>
            </label>
        </>
    );
}
import React from "react";


export default function ColorMarker({ coordinates }) {

    return (

        /** Subtract half the diameter of the marker 
         *  when setting it's location
         */
        <div className="color-organizer-marker" 
             id="color-organizer-marker" 
             style={ { left: `${coordinates.x - 4}px`, 
                       top:  `${coordinates.y - 4}px` } }
        ></div>

    );
    
};

import React, { useEffect, useState } from "react";

import axios from "axios";

import "@wordpress/block-library/build-style/common.css";
import "@wordpress/block-library/build-style/style.css";
import "@wordpress/block-library/build-style/theme.css";

import Page from "./Page.js";
import Post from "./Post.js";




export default function Feed({ posts, type, apiRoot }) {
    const [pageStyle, setPageStyle] = useState("");
    
    // Get the content's 
    useEffect(() => {
        axios.get(`${apiRoot}/wp-json/theme-css/v1/variables`)
            .then((response) => {
                // console.log("Theme CSS variables:", response.data);
                setPageStyle(response.data);
            })
            .catch((error) => {

            });

    }, []);

    let feedContainerClassName = "group-box horizontal wrap h-align-center";
    if (type == "list") {
        feedContainerClassName = "group-box vertical no-wrap h-align-center";
    }

    const postComponents = posts.map((post, index) => {
        // console.log("Post ID:", post.id);
        return  (
            <Post post={ post } key={ index } type="post" width={ type == "list" ? "100%" : "" } apiRoot={ apiRoot } />
        )
    });

    return (
        <>
            <div className={feedContainerClassName}>
                {postComponents}
            </div>
            <style dangerouslySetInnerHTML={{ __html: pageStyle}} />
        </>
    )
}

import React, { forwardRef, 
                useEffect,
                useImperativeHandle,
                useRef,
                useState } from "react";

import ImageUploading from "react-images-uploading";

import Canvas from "./Canvas.js";
import Color  from "./Color.js";


/** ****************************************************************************
 *  Images Menu Component
 *  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 *  v.0.1 [2024-05-02 000000]
 *   
 * 
 * 
 * 
 *  ****************************************************************************
 */
const ImagesMenu = forwardRef(function({ images              = [],
                                         imageNumberLimit    = 10,
                                         selectedImageIndex  =  0,
                                         selectedHue         =  0,
                                         onChangeImages,
                                         onChangeImageCanvasComponent,
                                         onChangePickerCanvasComponent,
                                         onChangeSelectedImageIndex,
                                         pickerCanvasSize },

                                         canvasRefs) {

    const pickerDefaultColorData = (() => { 
        let colorData = [];
        for (let brightness = 1; brightness >= 0; brightness -= (1 / (pickerCanvasSize - 1))) {
            for (let saturation = 0; saturation <= 1; saturation += (1 / (pickerCanvasSize - 1))) {
                colorData.push(new Color(selectedHue, saturation, brightness));
            }
        }
        return colorData;
    })();

    const [pickerColorData, setPickerColorData] = useState(Array.from(pickerDefaultColorData));

    const [imageComponents,     setImageComponents]     = useState([]);
    const [imageCanvasIsLoaded, setImageCanvasIsLoaded] = useState(false);
    
    const imageCanvasRef  = useRef();
    const pickerCanvasRef = useRef();

    useImperativeHandle(canvasRefs, () => ({
        get imageCanvas() {
            return imageCanvasRef.current;
        },
        get pickerCanvas() {
            return pickerCanvasRef.current;
        }
    }));


    useEffect(() => { 
        /**
         *  Create components for each image
         */
        const newImageComponents = [];
        for (let i = 0; i < images.length; i++) {

            newImageComponents.push(
                <div className="color-organizer-image" 
                    key={ i }
                    style={ { 
                        border: `${ selectedImageIndex == i ? "0.1rem solid magenta" : 
                                                            "0.067rem solid gray" }`, 
                        boxSizing:  "border-box", 
                        position:   "relative", 
                        width:      "4rem",
                        minWidth:   "4rem", 
                        height:     "4rem",
                        maxHeight:  "100%",
                        margin:     "1rem 0.15rem",
                        backgroundImage:    `url(${ images[i]["data_url"].replace(/(\r\n|\n|\r)/gm, "") })`,
                        backgroundSize:     "contain",
                        imageRendering:     "-moz-crisp-edges", 
                        backgroundRepeat:   "no-repeat",
                        backgroundPosition: "center"
                    } }
                    onClick={ () => { onChangeSelectedImageIndex(i); } }>
                </div>
            );
        }
        setImageComponents(newImageComponents);
    }, [selectedImageIndex, selectedHue]);


    useEffect(() => { 
        /**
         *  Create a canvas element for the selected image
         */
        if (images[selectedImageIndex] !== undefined &&
            images[selectedImageIndex].data_url !== undefined) {

            const imageCanvasComponent = <Canvas imageData       = { images[selectedImageIndex].data_url } 
                                                additionalStyle = { { display: "none" } }
                                                canvasWidth     = { pickerCanvasSize }
                                                canvasHeight    = { pickerCanvasSize }
                                                isLoaded        = { (value) => setImageCanvasIsLoaded(value) } 
                                                ref = { imageCanvasRef } />;

            onChangeImageCanvasComponent(imageCanvasComponent);
        }
    }, [selectedImageIndex, selectedHue]);


    useEffect(() => {
        /**
         *  Create a canvas component for the picker
         */
        if (imageCanvasRef && imageCanvasRef.current && imageCanvasIsLoaded) {
            let imagePixelData = getCanvasPixelData(imageCanvasRef.current, 0, 0, imageCanvasRef.current.width, imageCanvasRef.current.height);
            let uniqueColors   = getUniqueArrayElements(imagePixelData).map((element) => new Color(null, null, null,
                                                                                                element[0], element[1], element[2], null,
                                                                                                element[3]));            
            let pickerAdjustedColorData = getPickerAdjustedColorData(uniqueColors);
            
            const pickerPixelData = pickerAdjustedColorData.map((element) => [element.red, element.green, element.blue, 255])
                                                            .flat();
            
            const pickerCanvasComponent = <Canvas pixelData    = { pickerPixelData }
                                                canvasWidth  = { pickerCanvasSize } 
                                                canvasHeight = { pickerCanvasSize }
                                                additionalStyle = { {
                                                    pointerEvents: "none",
                                                    position:      "absolute" 
                                                } }
                                                ref = { pickerCanvasRef } />;

            onChangePickerCanvasComponent(pickerCanvasComponent);
        }
    }, [selectedImageIndex, selectedHue]);




    function getCanvasPixelData(canvas, startX, startY, width, height) {
        let pixelData  = [];
        let imageData  = canvas.getContext("2d").getImageData(startX, startY, width, height);
        let canvasData = imageData.data;

        for (let i = 0; i < canvasData.length; i += 4) {
            pixelData.push([canvasData[i+0],
                            canvasData[i+1],
                            canvasData[i+2],
                            canvasData[i+3]]);
        }
        return pixelData;
    }


    function getUniqueArrayElements(array) {
        let arrayAsObject = {};

        array.forEach((element) => {
            arrayAsObject[`${element}::${typeof(element)}`] = element;
        });

        return Object.keys(arrayAsObject).map((property) => {
            return arrayAsObject[property];
        });
    }

    // When selecting image: Generate default color space and return while async operation happens
    // When changing hue: Return the existing color space if the hue has changed by less than like
    //     15% or so, then continue as normal.
    function getPickerAdjustedColorData(availableColors) {        
        let pickerAdjustedColorData = []; 
        let index = 0;
        for (let brightness = 1; brightness >= 0; brightness -= (1 / (pickerCanvasSize - 1))) {
            index++;
            for (let saturation = 0; saturation <= 1; saturation += (1 / (pickerCanvasSize - 1))) {
                index++; 
                const pickerColor   = new Color(selectedHue, saturation, brightness);
                const adjustedColor = getMostSimilarColor(pickerColor, availableColors);
                pickerAdjustedColorData.push(adjustedColor);
            }
        }
        return pickerAdjustedColorData;
    }


    function getMostSimilarColor(color, colorArray) {
        let distances = {}; 

        // Filter out colors that are too different to reduce calculations
        // let filteredArray = array.filter((paletteColor) => {
        //     if ((color.hue - paletteColor.hue) > 100) { 
        //         return false;
        //     } else if ((color.saturation - paletteColor.saturation) > 0.75 ) { 
        //         return false;
        //     } else if ((color.brightness - paletteColor.brightness) > 0.67) {
        //         return false;
        //     } else {
        //         return true;
        //     }
        // });

        // if (filteredArray.length == 0) { 
        //     filteredArray = array; 
        // }

        for (let i = 0; i < colorArray.length; i++) {

            // Return without calculating distances if the colors are equal
            if (colorArray[i].hex == color.hex) { return colorArray[i] }
            distances[colorArray[i].hex] = getDistanceBetween(color, colorArray[i]);
        };
        const resultHex = Object.keys(distances).reduce((a, b, currentIndex) => { return !currentIndex || +distances[a] < +distances[b] ? a : b }, undefined);
        let resultColor = new Color(null, null, null, null, null, null, 
                                    resultHex);
        
        return resultColor;
    }


    function getDistanceBetween(color1, color2) {
        // Sqrt function call can be removed to save on performance without affecting the resulting color space
        return /* Math.sqrt( */ (color1.red - color2.red)**2 + (color1.green - color2.green)**2 + (color1.blue - color2.blue)**2 /* ) */;
    }


    /** ========================================================================
     *  Return 
     *  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
     */
    return (
        <div style={ {
                backgroundColor: "lightgray",
                borderRadius:    "1rem",
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                justifyContent: "flex-start",
                width: "var(--color-organizer-picker-size)",
                maxWidth: "var(--color-organizer-picker-size)" } }>

            <ImageUploading multiple
                            value={ images }
                            onChange={ onChangeImages }
                            maxNumber={ imageNumberLimit }
                            dataURLKey="data_url"
                            >{ 
                ({ imageList,
                   onImageUpload,
                   onImageRemoveAll,
                   onImageUpdate,
                   onImageRemove,
                   isDragging,
                   dragProps }) => (
                    <div className="upload__image-wrapper">
                        <button style={isDragging ? { color: 'red' } : undefined}
                                onClick={ onImageUpload }
                                { ...dragProps }>
                            Click or Drop here
                        </button>
                        &nbsp;
                        <button onClick={ onImageRemoveAll }>Remove all images</button>
                    </div>
                ) }
            </ImageUploading>


            {/* <div style={ { display: "flex",
                           flexWrap: "nowrap",
                           paddingLeft: "0.3rem" } }> 
                { imageCanvasComponent }
            </div> */}
            <div style={ {
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                height: "5rem",
                flexWrap: "nowrap",
                overflowX: "scroll",
                overflowY: "hidden"
                } }>

                { imageComponents }
            </div>
        </div>
    );
});

export default ImagesMenu;
import React from "react";
import ColorSwatch from "./ColorSwatch";
import ColorPreview from "./ColorPreview";


export default function ColorPalette({ currentColor, 
                                       colors, 
                                       selectedIndex, 
                                       selectSwatch, 
                                       replaceSwatch, 
                                       deleteSwatch, 
                                       addSwatch, 
                                       clearPalette }) {
    
    const colorSwatches = [];
    const colorSwatchCount = colors.length > 12 ? colors.length : 12;

    for (let i = 0; i < colorSwatchCount; i++) {
        colorSwatches.push(
            <div className="transparent-texture">
                <ColorSwatch color={colors[i]} index={i} selected={selectedIndex == i} selectSwatch={selectSwatch} key={i} />
            </div>
        );
    }

    return (
        <div className="color-organizer-palette-box">
            <ColorPreview color={currentColor} />
            <div className="group-box horizontal h-align-center">
                {colorSwatches}
            </div>
        </div>
    )    
};

import { forwardRef,
         useImperativeHandle,
         useRef,
         useState } from "react";

import ColorMarker from "./ColorMarker";
import HueMarker   from "./HueMarker";


/** ****************************************************************************
 *  ColorPicker
 *  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 *  v0.1 [2024-05-04 000000]
 *  Blake (organizedSlop)
 * 
 *  ||||||||||
 * 
 *  ****************************************************************************
 */
const ColorPicker = forwardRef(function({ currentColor,
                                          markerCoordinates,
                                          hueSelectorCanvasComponent = <></>,
                                          hueSelectorOnClick, 
                                          hueSelectorOnDrag,
                                          pickerCanvasComponent = <></>,
                                          pickerOnClick,
                                          pickerOnDrag },
                                          
                                          ref) {

    const [isShowingCanvasComponents, setIsShowingCanvasComponents] 
        = useState( pickerCanvasComponent.type      !== (<></>).type &&
                    hueSelectorCanvasComponent.type !== (<></>).type);

    const divOpacity = isShowingCanvasComponents ? 0 : 1;

    const pickerRef      = useRef();
    const hueSelectorRef = useRef();

    useImperativeHandle(ref, () => ({
        get picker() {
            return pickerRef.current;
        },
        get hueSelector() {
            return hueSelectorRef.current;
        }
    }));


    /** ========================================================================
     *  Return
     *  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
     */
    return (
        <div className="color-organizer-picker-box">
            <div className="color-organizer-transparent-texture">
                
                { pickerCanvasComponent }
                
                <div className   = "color-organizer-picker" 
                     onClick     = { pickerOnClick }
                     onMouseMove = { pickerOnDrag } 
                     style       = { { opacity: divOpacity,
                                       background: `-moz-linear-gradient(to top, rgba(0, 0, 0, ${ currentColor.alpha }), transparent), 
                                                    linear-gradient(to left, hsla(${ currentColor.hue }, 100%, 50%, ${ currentColor.alpha }), rgba(255, 255, 255, ${ currentColor.alpha }))` } } 
                     ref         = { pickerRef }>
                    
                    <ColorMarker coordinates={ markerCoordinates } />
                </div>
            </div>


            { hueSelectorCanvasComponent }
            
            <div className   = "color-organizer-hue-selector"
                 onClick     = { hueSelectorOnClick }
                 onMouseMove = { hueSelectorOnDrag }
                 style       = { { opacity: divOpacity } }
                 ref         = { hueSelectorRef }>

                <HueMarker hue={ currentColor.hue } />
            </div>
        </div>
    );
});

export default ColorPicker;